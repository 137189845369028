import React, { useEffect, useState } from 'react';
import { Container, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box } from '@mui/material';
import axios from 'axios';
import SidebarMenu from '../../layout/SidebarMenu';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import TablePagination from '@mui/material/TablePagination';


const ConsumedTickets = () => {
  const [tickets, setTickets] = useState([]);

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Les mois sont indexés de 0 à 11
    const year = date.getFullYear();
    
    return `${day}/${month}/${year}`;
  };

  const [page, setPage] = React.useState(2);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    //axios.get(`http://localhost:8081/api/qrcode/used-tickets`, {withCredentials:true})
    axios.get(`http://92.222.178.45:8052/api/qrcode/used-tickets`, {withCredentials:true})
      .then(response => {
        setTickets(response?.data);
        console.log("tickets", response.data);
        console.log("tickets", tickets);
      })
      .catch(error => {
        console.error('There was an error fetching the tickets!', error);
      });
  }, []);

  return (
    <Container maxWidth="md" sx={{ marginTop: 15 }}>
        <SidebarMenu/>
      <Typography variant="h4" component="h1" gutterBottom>
        Liste des billets consommés
      </Typography>
      <h2>Total des billets :{tickets.length}</h2>
       <Box sx={{ mt: 2, width: '100%' }}> 
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
                <TableRow>
                <TableCell>Nom du Partenaire</TableCell>
                <TableCell>Nom du Bénéficiaire</TableCell>
                <TableCell>Date de Validité</TableCell>
                <TableCell>Téléphone</TableCell>
                <TableCell>Status</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {tickets && 
                (tickets?.slice(page*rowsPerPage, page*rowsPerPage+rowsPerPage)
                  .map((ticket) => (
                <TableRow key={ticket.id}>
                    <TableCell>{ticket.supplier}</TableCell>
                    <TableCell>{ticket.userName}</TableCell>
                    <TableCell>{formatDate(ticket.willExpiredAt)}</TableCell>
                    <TableCell>{ticket.phoneNumber}</TableCell>
                    <TableCell>{ticket.status}</TableCell>

                </TableRow>
                )))}
            </TableBody>
            </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={100}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
          </Box>
    </Container>
  );
}

export default ConsumedTickets;
