import React, {useEffect} from 'react';
import { styled, useTheme } from '@mui/material/styles';
import {Tab, Tabs,  Menu, MenuItem,Typography } from '@mui/material'
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import { AccountCircle } from '@mui/icons-material';
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';
import EventSeatIcon from '@mui/icons-material/EventSeat';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import {Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

 const SidebarMenu = () => {


  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const [userConnectionData, setUserConnectionData] = useState({});

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.removeItem('userInfo');
    navigate("/");
  };

  // const userData = localStorage.getItem('userInfos')
  // console.log("ddddd",userData);

  // const getUserInfo = () => {
  //   const userInfo = localStorage.getItem('userInfo');
  //   return userInfo ? JSON.parse(userInfo) : null;
  // };
  

 useEffect( () => {
  const userData = localStorage.getItem('userInfo');
  if (userData) {
    setUserConnectionData(JSON.parse(userData));
  }
 }, [])

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
    {/* userData? (  */}
      <AppBar position="fixed" open={open} sx={{background: '#e13835'}}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>
          {/* <Typography variant="h6" noWrap component="div">
            Persistent drawer
          </Typography> */}
          <AutoFixHighIcon fontSize="large"/>
            <Tabs  textColor="white">
            {/* <Typography>  */}
                <Tab label='Billeterie'/>
                {/* <Tab label="Tickets" />
                <Tab label="Item Three"/> */}
            {/* </Typography> */}
            </Tabs>
            <Box sx={{
                    marginLeft:'auto',
                    display: "flex",
                    alignItems:"center"
              }}> 

                 {/* Hello */}
                <Typography  ml={2}>{userConnectionData?.lastName }</Typography>
                {
                  userConnectionData?.lastName ? (
                    <Typography  ml={2}>
                    {userConnectionData.firstname}
                  </Typography>
                  ) : ''
                }
              <IconButton 
                  sx={{
                      color: '#f5f5f5',
                      marginLeft:'auto'
                  }}
                  onClick={handleClick}
              >
                  <AccountCircle fontSize="large"/>
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem 
                  onClick={handleLogout}
                >
                    Se déconnecter
                </MenuItem>
                {/* <MenuItem  
                  onClick={() => {
                    navigate("/createAccount")}
                  }
                >
                  Créer un compte
                </MenuItem> */}
              </Menu> 
            </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {/* {['Acceuil', 'Evènements', 'Inscription', 'Inscrits'].map((text, index) => (
            <ListItem key={text} disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItemButton>
            </ListItem>
          ))} */}
          {/* <ListItem  disablePadding>
              <ListItemButton component={Link} to="/">
                <ListItemIcon>
                <SpaceDashboardIcon />
                </ListItemIcon>
                <ListItemText primary="Acceuil" />
              </ListItemButton>
            </ListItem> */}
           <ListItem  
              disablePadding   
              onClick={() => {
                navigate("/welcome");
              }}>
              <ListItemButton >
                <ListItemIcon>
                <AppRegistrationIcon/>
                {/* <SpaceDashboardIcon AppRegistrationIcon/> */}
                </ListItemIcon>
                <ListItemText primary="Vente" />
              </ListItemButton>
            </ListItem>
            <ListItem  disablePadding 
            onClick={() => {navigate("/validate");
                  // onClose();
                }}>
              <ListItemButton component={Link}
              >
                <ListItemIcon>
                <EventSeatIcon />
                </ListItemIcon>
                <ListItemText primary="Consommation" />
                {/* <ListItemText primary="Evènements" /> */}
              </ListItemButton>
            </ListItem>
            <ListItem  disablePadding onClick={() => {
                  navigate("/consume")}}>
              <ListItemButton>
                <ListItemIcon>
                <HowToRegIcon />
                </ListItemIcon>
                <ListItemText primary="Tickets consommés" />
              </ListItemButton>
            </ListItem>
            <ListItem  disablePadding onClick={() => {
                  navigate("/unused-tickets")}}>
              <ListItemButton>
                <ListItemIcon>
                <AppRegistrationIcon />
                </ListItemIcon>
                <ListItemText primary="Tickets non consommés" />
              </ListItemButton>
            </ListItem>
        </List>
        {/* <Divider />
        <List>
          {['All mail', 'Trash', 'Spam'].map((text, index) => (
            <ListItem key={text} disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List> */}
      </Drawer>
      {/* <Main open={open}>
        <DrawerHeader />
        <Typography paragraph>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
          tempor incididunt ut labore et dolore magna aliqua. Rhoncus dolor purus non
          enim praesent elementum facilisis leo vel. Risus at ultrices mi tempus
        
        </Typography>
        <Typography paragraph>
          Consequat mauris nunc congue nisi vitae suscipit. Fringilla est ullamcorper
          eget nulla facilisi etiam dignissim diam. Pulvinar elementum integer enim

        </Typography>
      </Main> */}
    {/* ): navigte("/") */}
    </Box>
  );
}

export default SidebarMenu;