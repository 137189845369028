import React, { useState } from 'react';
import { Container, Box, TextField, Button, Typography } from '@mui/material';
import axios from 'axios';
import { useAlert } from 'react-alert';
import Link from '@mui/material/Link';


const CreateAccount = () => {
    
      const [firstname, setFirstname] = useState('');
      const [lastName, setLastName] = useState('');
      const [email, setEmail] = useState('');
      const [phone, setPhone] = useState('');
      const [password, setPassword] = useState('');
      const [role, setRole] = useState('');
      const [userDate, setUserData] = useState([]);
      const alert = useAlert()
      const [isLoading, setIsLoading] = useState(false)

      const handleChange = (e) => {
        const { name, value } = e.target;
      }

      const handleSubmit = async (e) => {
        e.preventDefault();
        console.log('test')
        setIsLoading(true)
    
        const formData  = {
          lastName,
          firstname,
          email,
          phone,
          password,
        //   role
        }
        if(formData) {
            await axios.post(`http://92.222.178.45:8052/api/auth/register`, formData)
            .then((response) => {
                console.log(response);
                setUserData(response.data)
                alert.success("Création du compte réussit")
            })
            .catch((error) => {
                console.log(error)
            })
        }else{
            console.log("hh");
            alert.show('Veuillez renseigner tous les champs!')
        }
       
    }

return(
    <Container maxWidth="sm">
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        mt: 4,
        p: 3,
        borderRadius: 2, 
        boxShadow: 3,
        backgroundColor: 'background.paper'
      }}
    >
      <Typography variant="h4" component="h1" gutterBottom>
        Créer votre compte
      </Typography>
      <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2, width: '100%' }}>
        <TextField
          label="Nom"
          name="lastName"
          value={lastName}
          onChange={(event) => setLastName(event.target.value)}
          fullWidth
          required
          margin="normal"
        />
        <TextField
          label="Prénom"
          name="firstname"
          value={firstname}
          onChange={(event) => setFirstname(event.target.value)}
          fullWidth
          required
          margin="normal"
        />
       
         <TextField
          label="Email"
          name="name"
          type='email'
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          fullWidth
          required
          margin="normal"
        />
        <TextField
          label="Numéro de Téléphone"
          name="phone"
          value={phone}
          onChange={(event) => setPhone(event.target.value)}
          fullWidth
          required
          margin="normal"
        />
         <TextField
          label="Mot de passe"
          name="password"
          value={password}
          onChange={(event) => setPassword(event.target.value)}
          fullWidth
          required
          margin="normal"
        />
         {/* <TextField
          label="Role"
          name="role"
          value={role}
          onChange={(event) => setRole(event.target.value)}
          fullWidth
          required
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
        /> */}
        <Box display={"flex"}>
          <p>Vous avez deja un compte?</p>
          <Link 
            href="/" 
            sx={{mt:2, ml:'auto'}}
          >
            connectez-vous!
         </Link>
        </Box>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          sx={{ mt: 3 }}
        >
          Envoyer
        </Button>
      </Box>
    </Box>

    <Box></Box>
  </Container>
)

}

export default CreateAccount;