import React, { useState, useEffect} from 'react';
import { Container, Box, TextField, Button, Typography } from '@mui/material';
import axios from 'axios';
//import { useAlert } from 'react-alert';
import Link from '@mui/material/Link';
import { useNavigate } from 'react-router-dom';


const Authenticate = () => {
   
      const [email, setEmail] = useState('');
      const [password, setPassword] = useState('');
      const [authUserInfos, setAuthUserInfos] = useState({});
      const navigate = useNavigate();
      //const alert = useAlert()x
      const [isLoading, setIsLoading] = useState(false)
      const handleChange = (e) => {
        const { name, value } = e.target;
      }

  

    const handleSubmit = async (e) => {

      e.preventDefault();
      console.log('test')
      setIsLoading(true)
  
      const formDataConnection  = {
        email,
        password,
      }
     
      //let response = await axios.post(`http://localhost:8081/api/auth/login`, formDataConnection);
      let response = await axios.post(`http://92.222.178.45:8052/api/auth/login`, formDataConnection);
      if (response?.data) {
        const data  = response?.data
        setAuthUserInfos(data)  
       localStorage.setItem('userInfo', JSON.stringify(data));
       navigate("/welcome");
       alert("Connexion réussit")
      } else {
        alert("Informations de connexions incorrectes")
      }
  }

  const getUserInfo = () => {
    const userInfo = localStorage.getItem('userInfo');

    return userInfo ? JSON.parse(userInfo) : null;
  };
  

 useEffect( () => {
  const userData = getUserInfo()
  if (!userData) {
    navigate("/");
  }
 })


return(
  
    <Container maxWidth="sm">
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        mt: 4,
        p: 3,
        borderRadius: 2, 
        boxShadow: 3,
        backgroundColor: 'background.paper'
      }}
    >
      <Typography variant="h4" component="h1" gutterBottom>
        Connectez-vous
      </Typography>
      <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2, width: '100%' }}>     
         <TextField
          label="Email"
          name="name"
          type='email'
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          fullWidth
          required
          margin="normal"
        />
         <TextField
          label="Mot de passe"
          name="password"
          value={password}
          onChange={(event) => setPassword(event.target.value)}
          fullWidth
          required
          margin="normal"
        />
        <Box display={"flex"}>
          <p>Vous n'avez pas de compte?</p>
          <Link 
            href="/createAccount" 
            sx={{mt:2, ml:'auto'}}
          >
            Créez-le!
         </Link>
        </Box>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          sx={{ mt: 3 }}
        >
          Se connecter
        </Button>
      </Box>
    </Box>

    <Box></Box>
  </Container>
)

}

export default Authenticate;