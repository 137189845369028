import React, { useState, useRef } from 'react';
import { QrReader } from 'react-qr-reader';
import axios from 'axios';
import { styled } from '@mui/material/styles';
import QRCodeValidationPopup from './QRCodeValidationPopup';
import { Box, Card, Container, Grid, Typography, Button } from '@mui/material';
import Alert from '@mui/material/Alert';


const VerifiedTicket = () => {

  const [partenaire, setPartenaire] = useState('');
  const [beneficaire, setBeneficaire] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [identifiantTicket, setIdentifiantTicket] = useState('');
  const [scanWebcamResult, setScanWebcamResult] = useState('');
  const [endDate, setEndDate] = useState('');
  const [showConsomationBtn, setShowConsomationBtn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [ticketData, setTicketData] = useState([]);
  const [ticketStatus, setTicketStatus] = useState("");
  

 const getIdentifiants = async (data) => {
    let objectData = data.split(";");
    const result = {};
    objectData.forEach(data => {
        if (data && data.includes(":")) {
            let [key, value] = data.split(":");
            result[key.trim()] = value.trim();
        }
    });

    let partenaire = result["Partenaire"];
    let beneficiaire = result["Béneficiaire"];
    let endDate = result["Validité"];
    let phoneNumber = result["Téléphone"];
    let identifiantTicket = result["Identifiant du ticket"];

    setPartenaire(partenaire);
    setBeneficaire(beneficiaire);
    setEndDate(endDate);
    setPhoneNumber(phoneNumber);
    setIdentifiantTicket(identifiantTicket);

    try {
      const response = await axios.get(`http://92.222.178.45:8052/api/qrcode/${identifiantTicket}`, {withCredentials:true});
      // const response = await axios.get(`http://localhost:8081/api/qrcode/${identifiantTicket}`, {withCredentials:true});

      if (response.status === 200) {
        setTicketData(response.data);
        //console.log("dattaaaaa", response.data.id)
       // console.log("dattaaaaa", response.data.alreadyUse)
        if (response.data.alreadyUse === false){

            console.log("ticket", ticketData.alreadyUse);
            // setTicketStatus("Ticket valide et non utilisé")
            setShowConsomationBtn(true);
                alert("Ticket valide et non utilisé")
            // (   <div>
            //     <Alert icon={<CheckIcon fontSize="inherit" />} severity="warning">
            //          Ticket valide et non utilisé 
            //     </Alert>
            //     </div>
            // )
          
        }else{
        //     (   <div>
        //         <Alert icon={<CheckIcon fontSize="inherit" />} severity="warning">
        //             Titcket deja utilisé ou non valide 
        //         </Alert>
        //     </div>
        // )
         alert("Titcket deja utilisé ou non valide")

        }
      } else {
        console.log("Ticket not found");
      }
    } catch (error) {
      console.log('Erreur lors de la recherche du ticket', error);
      console.error(error);
    }

    return {
      beneficiaire: beneficiaire,
      ticketId: identifiantTicket
    };
  };

  const handleScanWebcamQRCode = (result) => {
    setIsLoading(true);
    if (result) {
        //console.log("ffffff");
        //console.log(result.text)
      setScanWebcamResult(result.text);
      getIdentifiants(result.text);
    } else {
      console.log("Aucune information contenue dans le QR code");
    }

    setIsLoading(false);
  };


  const handleScanError = (error) => {
    console.log(error);
  };

  const handleClose = () => {
    setShowConsomationBtn(false);
  };

  const handleConsume = async () => {
        setIsLoading(true);
        const response = await axios.post(`http://92.222.178.45:8052/qrcode/validate/${identifiantTicket}`, {},  {withCredentials:true});
        // const response = await axios.post(`http://localhost:8081/api/qrcode/validate/${identifiantTicket}`, {},  {withCredentials:true});
    if (!Object.keys(response).includes('error')) {
      setShowConsomationBtn(false);
      alert('Votre ticket a été consommé.');
    } else {
      alert('Une erreur a été rencontrée dans la vérification.');
    }
  };

  return (
    <Container maxWidth="md">
      <Box mt={4} display="flex" flexDirection="column" alignItems="center">
        <Typography variant="h4" gutterBottom>Vérification des tickets</Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Card variant="outlined">
              <QrReader
                delay={300}
                onResult={(data) => handleScanWebcamQRCode(data)}
                onError={handleScanError}
                style={{ width: '100%' }}
              />
            </Card>
          </Grid>
          {partenaire && (
            <Grid item xs={12} md={6}>
              <Typography variant="h6">Bénéficiaire: {beneficaire}</Typography>
              <Typography variant="h6">Partenaire: {partenaire}</Typography>
              <Typography variant="h6">Téléphone: {phoneNumber}</Typography>
              <Typography variant="h6">Validité: {endDate}</Typography>
              <Typography variant="h6">Identifiant du ticket: {identifiantTicket}</Typography>
            </Grid>
          )}
        </Grid>
        {showConsomationBtn && (
          <QRCodeValidationPopup
            open={showConsomationBtn}
            handleClose={handleClose}
            handleConsume={handleConsume}
          />
        )}
      </Box>
    </Container>
  );
};

export default VerifiedTicket;
