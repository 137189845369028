import React, { useState } from 'react';
import { Container, Box, TextField, Button, Typography } from '@mui/material';
import axios from 'axios';
//import { useAlert } from 'react-alert';


function CreateTicket() {
  const [formData, setFormData] = useState({
    name: '',
    phoneNumber: '',
    date: '',
  });

  const [userName, setUserName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [willExpiredAt, setWillExpiredAt] = useState('');
  //const alert = useAlert()
  const [isLoading, setIsLoading] = useState(false)
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('test')
    setIsLoading(true)

    const formData  = {
      userName,
      phoneNumber,
    }

    if(formData){ 
    console.log(userName);
      //console.log(withCredential);
     // await axios.post(`http://localhost:8081/api/qrcode/generate-ticket`, formData, {withCredentials:true, responseType: 'blob'}
      await axios.post(`http://92.222.178.45:8052/api/qrcode/generate-ticket`, formData, {withCredentials:true, responseType: 'blob'}
    )
     .then((response) =>{
        console.log(response)
       const data = response.data;

      console.log("fff", data);
      const url = window.URL.createObjectURL(data);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = 'ticket.pdf'; 
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
     })
     .catch((error) =>{
      //alert("Veuillez remplir tous les champs")
      console.log(error);
      // alert.error(
      //   <div style={{ textTransform: 'none' }}>
      //     {error ??
      //       "Une erreur a été rencontré lors de l'nrégistrement des informations."}
      //   </div>
      // )
     }
    )
  }else{
    console.log('test')
  }
  };

  return (
    <Container maxWidth="sm">
      <h1>Génération des tickets </h1>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          mt: 4,
          p: 3,
          borderRadius: 2, 
          boxShadow: 3,
          backgroundColor: 'background.paper'
        }}
      >
        <Typography variant="h4" component="h1" gutterBottom>
          Créer votre billet
        </Typography>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2, width: '100%' }}>
          <TextField
            label="Nom"
            name="name"
            value={userName}
            onChange={(event) => setUserName(event.target.value)}
            fullWidth
            required
            margin="normal"
          />
          <TextField
            label="Numéro de Téléphone"
            name="phoneNumber"
            value={phoneNumber}
            onChange={(event) => setPhoneNumber(event.target.value)}
            fullWidth
            required
            margin="normal"
          />
          {/* <TextField
            label="Date"
            name="date"
            type="date"
            value={willExpiredAt}
            onChange={(event) => setWillExpiredAt(event.target.value)}
            fullWidth
            required
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
          /> */}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mt: 3 }}
          >
            Envoyer
          </Button>
        </Box>
      </Box>

      <Box></Box>
    </Container>
  );
}

export default CreateTicket;
