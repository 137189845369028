import {
  BrowserRouter,
  Route,
  Routes,
} from "react-router-dom";
import Welcome from './pages/welcome';
import VerifiedQrcodeTicket from "./pages/tickets/VerifiedQrcodeTicket";
import ConsumedTickets from "./pages/tickets/ConsumeTicket";
import CreateAccount from "./pages/account/CreateAccount";
import Authenticate from "./pages/account/Authenticate";
import UnUsedTickets from "./pages/tickets/UnUsedTickets";


function App() {
  return( 
    <>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Authenticate />}></Route>
        <Route path="/createAccount" element={<CreateAccount />}></Route>
        <Route path="/welcome" element={<Welcome />}></Route>
        <Route path="/validate" element={<VerifiedQrcodeTicket />}></Route>
        <Route path="/consume" element={<ConsumedTickets />}></Route>
        <Route path="/unused-tickets" element={<UnUsedTickets />}></Route>
      </Routes>
    </BrowserRouter>
    </>

)
}

export default App;
