// QRCodePopup.js
import React from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';
//import { useAlert } from 'react-alert'

const QRCodeValidationPopup = ({ open, handleClose, handleConsume }) => {
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            <Box 
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    boxShadow: 24,
                    p: 4,
                }}
            >
                <Typography id="modal-title" variant="h6" component="h2">
                    QR Code Scanné
                </Typography>
                <Typography id="modal-description" sx={{ mt: 2 }}>
                    Voulez-vous consommer votre ticket ?
                </Typography>
                <Button onClick={handleConsume} variant="contained" sx={{ mt: 2 }}>
                    Consommer
                </Button>
            </Box>
        </Modal>
    );
};

export default QRCodeValidationPopup;
