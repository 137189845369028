import React from "react";
import { Box, Typography } from "@mui/material";

import CreateTicket from "../tickets/CreateTicket";
import SidebarMenu from "../../layout/SidebarMenu";
import VerifiedTicket from "../tickets/VerifiedTicket";

const Welcome = () => {

    return (
        <> 
        <Box>
            <h2>Acceuil</h2>
            <SidebarMenu/>
              <Typography paragraph>
                <CreateTicket/> 
                {/* <VerifiedTicket/> */}
            </Typography>
        </Box>
        </>
    )
}

export default Welcome;