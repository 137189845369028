import React from "react";
import { Box, Typography } from "@mui/material";
import SidebarMenu from "../../layout/SidebarMenu";
import VerifiedTicket from "../tickets/VerifiedTicket";

const VerifiedQrcodeTicket = () => {

    return (
        <> 
            <Box>
                <h2>Vérifier et activer les billtes</h2>
                    <SidebarMenu/>
                <Typography paragraph>
                    <VerifiedTicket/>
                </Typography>
            </Box>
        </>
    )
}

export default VerifiedQrcodeTicket;